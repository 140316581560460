.flag-dropdown{
    left: 0;
    top: 40px;
    padding: .5rem;
    background-color: white;
    border-radius: 8px;
    width: 50px;
    display: none;
}

.flag-dropdown > span{
    display: block;
    margin-bottom: .5rem;
}

.flag-dropdown > span:hover{
    background-color: #ebeefe;
}

.flag-dropdown > span:last-of-type{
    margin-bottom: 0;
}

.flag-dropdown img{
    width: 32px;
    height: auto;
}
.flag-dropdown:hover{
    display:block;
}
.flag-dropdown-anchor:hover .flag-dropdown{
    display:block;
}

.flag-dropdown button:first-of-type{
    margin-bottom: 8px;
}

.showUserDrop .loggedin_dropdown_options{
    display: flex;
}


.navbar_scrolled{
    background-color:#0c1135 !important;
    box-shadow: 0px 12px 12px -12px rgb(12 17 53 / 5%);
}

body.scrolled_up .navbar_container_scrolled{
    background-color: #0c1135 !important;
    position: fixed;
    top: 0;
}

.navbar_scrolled.type_2{
    background-color: white !important;
    box-shadow: 0px 12px 12px -12px rgb(12 17 53 / 5%);
}

body.scrolled_up .navbar_container_scrolled.type_2{
    background-color: white !important;
}

.mobile_menu{
    transition: all 0.3s ease-in-out;
}
.show_mobile_menu{
    transform: translateX(0);
    opacity: 1;
}

.type_2 button{
    color: #6D53E4;
}