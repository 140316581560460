.content-card3{
    position: relative;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
}

.content-card3::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    /* background: linear-gradient(91.1deg, #FFFFFF 37.64%, rgba(255, 255, 255, 0) 90.33%); */
    background: linear-gradient(90deg,rgba(12,17,53,.95) 30.8%,rgba(12,17,53,0));
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 8px;
}