
.App{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
footer{
    margin-top: auto;
}

.no_scroll{
    overflow: hidden;
}
.more_filters{
    opacity: 0;
    display: none;
}
.more_filters.show{
    opacity: 1;
    display: block;
    transform: translateX(0);
}

.custom_placeholder::placeholder{
    color: #57546D;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
}

.home_cover{
    background-image: url('/src/assets/images/theme/coverpage1.e542187f3e6919285833.png');
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
}

.topic_page_cover{
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 0;
}
.topic_page_cover:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(91.38deg, #0C1135 22.76%, rgba(12, 17, 53, 0) 86.39%);
    z-index: -1;

}

.bookCover img{
    object-fit: cover;
    border-radius: 2px 1px 1px 2px;
    filter: drop-shadow(2px 1px 7px rgba(0, 0, 0, 0.15));
}
.bookCover .shad{
    display: block;
    background-image: url('/src/assets/images/book_shadow.png');
    background-repeat: repeat-y;
    position: absolute;
    left: 0;
    height: 100%;
    top: 0;
    bottom: 0;
    width: .5rem;
}

input:focus, select:focus{
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.home_subject.active{
    border-color: #6D53E4;
}

.type_button_svg svg{
    transform: scale(0.7);
}
.type_button_svg.active svg g{
    fill: #6D53E4;
} 

.type-bite .type_button_svg.active svg g path, .type-ebook .type_button_svg.active svg g path{
    fill: #6D53E4;
} 

.topic_search_btn:hover svg path{
    stroke: white;
}

/* .content-card i{
    font-style: normal;
    margin-right: .5rem;
    letter-spacing: 0.05em;
    opacity: 0.6;
    text-transform: unset;
} */

.content-card:not(.ebook-card):hover{
    box-shadow: 0px 2px 4px rgba(12, 17, 53, 0.05);
}

.content-card.ebook-card:hover img{
    filter: drop-shadow(2px 1px 7px rgba(0, 0, 0, 0.35));
}

 .teacher_menu .ttools{
    fill:none;
    stroke:#57546d;
    stroke-linecap: round;
    strokeLinejoin: round;
    stroke-width: 1.5px;
}



.teacher_menu:hover .ttools{
    stroke: #6D53E4;
}

.content-card.has_access{
    border-left: 1px solid black;
}

.tooltip{
    padding: .5rem .5rem;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
    max-width: 500px;
}

.bannerContentStyle a, 
.bannerContentStyle button{
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-self: flex-start;
    color: #6D53E4;
    letter-spacing: 0.05em;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    width: 100%;
}

.bannerContentStyle a:hover, 
.bannerContentStyle button:hover{
    opacity: .8;
}

.bannerContentStyle a svg,
.bannerContentStyle button svg{
    margin-left: .5rem;
}

.subject_card.allSubjects{
    background-color: transparent;
}

.subject_card.allSubjects:hover{
    color: #6D53E4;
    opacity: .8;
}

.subject_card.allSubjects .subject_icon{
    background-color: transparent;
}


.pagination_ul >li:first-of-type{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.pagination_ul >li:last-of-type{
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.pagination_ul li.active{
    pointer-events: none;
    background-color: #6D53E4;
}


.shimmerBG {
    -webkit-animation-duration: 2.2s;
            animation-duration: 2.2s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: shimmer;
            animation-name: shimmer;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    background: #ddd;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    background-size: 1200px 100%;
  }
  @-webkit-keyframes shimmer {
    0% {
      background-position: -100% 0;
    }
    100% {
      background-position: 100% 0;
    }
  }
  @keyframes shimmer {
    0% {
      background-position: -1200px 0;
    }
    100% {
      background-position: 1200px 0;
    }
  }


.highlight{
    background-color: #FAEEDD; 
    color: #0C1135;
}

.select_container.active p{
    color: #6D53E4;
}
  
.select_container.active svg{
    transform: rotate(180deg);
    opacity: .5;
}

.up_link_container {
    position: fixed;
    display: none;
    bottom: 2rem;
    right: 1rem;
    z-index: 200;
    width: 3rem;
}
  
.up_link_container .up_link {
    display: flex;
    width: 100%;
    height: 3rem;
    padding: 0;
    border-radius: 8px;
    line-height: 1rem;
    justify-content: center;
    align-items: center;
    background-color: #ece9fc;
    text-decoration: none !important;
    color: #6d53e4;
}

body.content_scrolled .up_link_container {
    display: block;
}

@media screen and (max-width: 1435px) {
    .banners_container{
        justify-content: center;
    }
    .banners_container .banner_card{
        margin-right: 0;
        margin-bottom: 1rem;
    }
}

@media screen and (min-width: 768px) {
    .banners_container .banner_card {
        margin-right: 1rem;
    }
    .banners_container .banner_card:last-of-type{
        margin-right: 0;
    }
    .up_link_container {
        bottom: 5rem;
    }
}

/* @media screen and (max-width: 1177px) {
    .home_cover{
        background-size: cover;
    }
} */


@media screen and (max-width: 1024px) {
    .home_cover{
        background-position: bottom right;
    }
}

@media screen and (max-width: 1279px) {
    .select_options{
        background:
        /* Shadow Cover TOP */
        linear-gradient(
        white 30%,
        rgba(255, 255, 255, 0)
        ) center top,
        
        /* Shadow Cover BOTTOM */
        linear-gradient(
        rgba(255, 255, 255, 0), 
        white 70%
        ) center bottom,
        
        /* Shadow TOP */
        radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
        ) center top,
        
        /* Shadow BOTTOM */
        radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
        ) center bottom;
        background-color: white;
        background-repeat: no-repeat;
        background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
        background-attachment: local, local, scroll, scroll;
    }
}

/* @media screen and (max-width: 639px) {
    .front_level{
        background-color: white;
        color: #6D53E4;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        width: 4.5rem;
        height: 4.5rem;
        font-size: 14px;
        margin-left: .5rem;
        margin-right: .5rem;
        margin-bottom: 1rem;
        padding-bottom: 0;
        border: none;
    }
    .front_level.active{
        background-color: #6D53E4;
        color: white;
    }
    .front_level:last-of-type{
        width: auto;
        border-radius: 16px;
        padding: 8px;
    }
    .front_level_container{
        justify-content: center;
        align-items: center;
        background: #ebeefe;
        margin: 0 -3vw;
        padding: 16px 3vw 8px 3vw;
        position: relative;
    }
    .front_level_container:after{
        content: '';
        position: absolute;
        left: 50%;
        top: 100%;
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 10px solid #ebeefe;
        transform: translate(-50%, 0);
        clear: both;
    }
} */

.TOC-wrapper{
    position: relative;
    overflow: hidden;
}

.TOC-content{
    padding: 32px 0;
}

.shadowOverflow{
    display: none;
}
@media (min-width: 992px) {
    .shadowOverflow {
        display: block;
        position: absolute;
        left: 0;
        z-index: 100;
        width: 100%;
        height: 32px;
    }
  
  .shadow--top {
    top: 0;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 70%);
    opacity: 0;
  }
  
  .shadow--bottom {
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 70%);
  }
}

/* Customize the label (the container) */
/* .checkbox-container {
    display: block;
    position: relative;
    padding-left: 2.75rem;
    padding-top: 4px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: font-size 0.3s ease-out;
  }
  
  /* Hide the browser's default checkbox
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  } */
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 2rem;
    width: 2rem;
    border: 1px solid #938FA8;
    border-radius: 8px;
    background-color: white;
  }


  .checkbox-container {
    position: relative;
    /* display: inline-block; */
    height: 32px;
    padding-left: 4rem;
  }
  
  .checkbox-container input { 
    opacity: 0;
    width: 0;
    height: 0;
  }

  .checkbox-container .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 3.5rem;
    bottom: 0;
    background-color: #ffffff;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .checkbox-container .slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 4px;
    bottom: 4px;
    background-color: #BBC5FC;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .checkbox-container input:checked + .slider {
    background-color: #6D53E4;
  }
  
  .checkbox-container input:checked + .slider:before {
    background-color: white;
  }
  
  .checkbox-container input:checked + .slider:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
  }
  
  /* Rounded sliders */
  .checkbox-container .slider.round {
    border-radius: 32px;
  }
  
  .checkbox-container .slider.round:before {
    border-radius: 50%;
  }


  /* #BBC5FC */
  
  .radio .checkmark{
    border-radius: 2rem;
  }

  .checkbox-container:hover input ~ .checkmark {
    background-color: #ebeefe;
  }

  /* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
    background-color: #6D53E4;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.24797 20.882L1.61697 14.25C1.08674 13.6903 0.795823 12.9458 0.806111 12.1748C0.816399 11.4039 1.12708 10.6674 1.67206 10.122C2.21704 9.57668 2.95331 9.26547 3.72423 9.25463C4.49515 9.24379 5.23987 9.53417 5.79997 10.064L10.018 14.282L17.918 3.749C18.1511 3.43783 18.4433 3.17564 18.7778 2.97738C19.1123 2.77913 19.4826 2.6487 19.8675 2.59354C20.2524 2.53839 20.6443 2.55958 21.0211 2.65591C21.3978 2.75225 21.7518 2.92184 22.063 3.155C22.3741 3.38816 22.6363 3.68033 22.8346 4.01482C23.0328 4.34932 23.1633 4.71958 23.2184 5.10448C23.2736 5.48938 23.2524 5.88137 23.1561 6.25808C23.0597 6.63479 22.8901 6.98883 22.657 7.3L12.71 20.565C12.4554 20.9043 12.1309 21.1849 11.7585 21.3879C11.3861 21.5909 10.9744 21.7115 10.5513 21.7416C10.1282 21.7716 9.7036 21.7104 9.30621 21.5621C8.90882 21.4138 8.54793 21.1819 8.24797 20.882Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1rem;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }


  /* class to hide scroll bar for scrollable elements */
  .hidden-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .hidden-scrollbar{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .not-selectable{
    
        -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
           -khtml-user-select: none; /* Konqueror HTML */
             -moz-user-select: none; /* Firefox */
              -ms-user-select: none; /* Internet Explorer/Edge */
                  user-select: none; /* Non-prefixed version, currently
                                        supported by Chrome and Opera */
    
  }

  .contentOverlayed{
    position: relative;
  }
  /* .contentOverlay{
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.4) 10%, rgba(255,255,255,0) 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
  } */