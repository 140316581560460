.ebookcontainer{
    -webkit-tap-highlight-color: transparent;
}
.singleEbookView_container::before{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    background: linear-gradient(37.19deg, #EBEFFF 27.48%, rgba(245, 247, 255, 0) 48.16%);
    z-index: -1;
}

.slick-slider{
    touch-action: auto;
-ms-touch-action: auto;
    /* scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll; */
}

.slick-list .slick-slide{
    /* scroll-snap-align: start; */
}

.slick-slide:first-of-type > div {
    margin-left: 0;
}

.slick-slide:last-of-type > div {
    margin-right: 0;
}

.slick-slide > div {
    margin: 0 12px;
}