.banner_card{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 0;
}


.banner_card.overlay::after{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(12, 17, 53, 0.8) 30.8%, rgba(12, 17, 53, 0) 100%);
    z-index: -1;
    border-radius: 8px;
}

.banner_card_link{
    transition: all 0.1s ease-in-out;
}

.banner_card:hover .banner_card_link{
    transform: translateX(10px);
}