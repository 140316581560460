.card_loading {
    position: relative;
    /* box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1); */
    background-color: #FFF;
    overflow: hidden;
  }
  .card_loading .shimmerBG {
    -webkit-animation-duration: 2.2s;
            animation-duration: 2.2s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: shimmer;
            animation-name: shimmer;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    background: #ddd;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    background-size: 1200px 100%;
  }
  @-webkit-keyframes shimmer {
    0% {
      background-position: -100% 0;
    }
    100% {
      background-position: 100% 0;
    }
  }
  @keyframes shimmer {
    0% {
      background-position: -1200px 0;
    }
    100% {
      background-position: 1200px 0;
    }
  }
  